import React, { FC } from "react"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import Link from "src/utils/Link"
import { usePages } from "src/context/pages"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const LogosList: FC<Props> = ({ className, text }) => {
  const { getPage } = usePages()
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "pricing-logos.png" }) {
        childImageSharp {
          fluid(maxWidth: 340, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "customer-logos-global.png" }) {
        childImageSharp {
          fluid(maxWidth: 875, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <div className={`${className} ${styles.container}`}>
      <p className={styles.logosTitle}>{text}</p>
      <Link className={styles.logosList} to={getPage("customers").url}>
        <Img
          fluid={sources}
          placeholderStyle={{ visibility: "hidden" }}
          imgStyle={{ objectFit: "contain" }}
          style={{ height: "100%", width: "100%" }}
        />
      </Link>
    </div>
  )
}

export default LogosList
