import React, { FC } from "react"
import * as styles from "./styles.module.scss"
import { Props } from "./types"
import { usePages } from "src/context/pages"
import { graphql, useStaticQuery } from "gatsby"
import { useComponentTexts } from "src/hooks/useComponentTexts"
import Link from "src/utils/Link"
import LogosList from "src/components/LogosList"

const GetStarted: FC<Props> = ({
  className,
  description,
  logosTitle,
  variant,
}) => {
  const { getPage } = usePages()
  const data = useStaticQuery(graphql`
    query {
      allFile (filter: { relativePath: {regex: "/components/Banners/GetStarted\/.+\\.yml/"}}) {
        nodes {
          childYaml {
            title
            description
            buttonText
            logosTitle
          }
          relativePath
        }
      }
    }
  `)
  const texts = useComponentTexts(data)
  return (
    <div
      className={`${styles.banner} ${
        variant === "uses" ? styles.uses : ""
      } ${className}`}
    >
      <div className={styles.bannerContent}>
        <h3 className={styles.title}>{texts.title}</h3>
        <p className={styles.description}>
          {description ? description : texts.description}
        </p>
        <Link
          to={getPage("signup").url}
          className={`button button-second ${styles.button}`}
        >
          {texts.buttonText}
        </Link>
      </div>
      <div className={styles.logosContainer}>
        <LogosList text={logosTitle ? logosTitle : texts.logosTitle} />
      </div>
    </div>
  )
}

export default GetStarted
